<template>
  <div class="background-view" :style="{backgroundImage}">
    <img class="background-image" v-if="image" :src="image" alt="">
    <div class="background-view-content">
      <slot>No Content</slot>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive } from 'vue'
export default {
  name: 'backgroundView',
  props: {
    /**
     * @param { string } image 背景图片
     * @param { array } colors 背景颜色
     * 
     * */ 
    colors: Array,
    image: String
  },
  setup (props) {
    let backgroundImage = null
    if (props.colors) {
      if (props.colors.length == 1) {
        backgroundImage = `linear-gradient(0deg,${props.colors[0]},${props.colors[0]})`
      } else {
        backgroundImage = `linear-gradient(${props.colors.join(',')})`
      }
    }
    const data = reactive({
      backgroundImage
    })
    return {
      ...toRefs(data)
    }
  }
}
</script>

<style lang="scss" scoped>
 .background-view {
    width: 100%;
    position: relative;
    overflow: hidden;
    background-size: 100%;
    background-repeat: no-repeat;
    transition: 0.3s;
 }
 .background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
 }
 @media screen and (min-width: 990px) {
  .background-view-content {
    width: 1250px;
    margin: 0 auto;
    position: relative;
  }
 }
 @media screen and (max-width: 990px) {
  .background-view-content {
    width: 100%;
    padding: 0 20px;
    position: relative;
  }
 }
</style>