import { createI18n } from 'vue-i18n'
import zhCN from './zh-CN.js'
import enUS from './en-US.js'

const i18n = createI18n({
  locale: window.localStorage.getItem('language') || 'zh-CN',
  legacy: false,
  globalInjection: true,
  messages: {
    'zh-CN': zhCN,
    'en-US': enUS
  }
})

export default i18n