import Http from '@/utils/http'

class ApiHttp extends Http {
  constructor () {
    super()
  }
  // 获取导航栏菜单
  async getMenus () {
    return await this._callApi({
      url: '/general/menus',
      method: 'get',
    })
  }
  async getMenusHtml (id) {
    return await this._callApi({
      url: '/general/menus/' + id,
      method: 'get',
    })
  }
  // 获取全部配置(同)
  async getDigitizationConfig (params) {
    return await this._callApi({
      url: '/general/digitization/list',
      method: 'get',
      params,
    })
  }
  // 获取解决方案列表
  async getSolutionList (params) {
    return await this._callApi({
      url: '/general/solution/list',
      method: 'get',
      params
    })
  }
  // 获取解决方案详情
  async getSolutionDetail (params) {
    return await this._callApi({
      url: '/general/solution/detail/' + params.id,
      method: 'get',
    })
  }
  // 获取技术服务列表
  async getTechnicalList () {
    return await this._callApi({
      url: '/general/technical/list',
      method: 'get',
    })
  }
  // 获取品牌列表
  async getBrandList (params) {
    return await this._callApi({
      url: '/general/brand/list',
      method: 'get',
      params,
    })
  }
  // 获取产品品牌列表
  async getProductBrandList (params) {
    return await this._callApi({
      url: '/general/product/brand/list',
      method: 'get',
      params,
    })
  }
  // 获取产品信息列表
  async getProductList (params) {
    return await this._callApi({
      url: '/general/product/list',
      method: 'get',
      params,
    })
  }
  // 获取产品详情
  async getProductDetail (params) {
    return await this._callApi({
      url: '/general/product/detail',
      method: 'get',
      params,
    })
  }
  // 获取岗位列表
  async getEmploymentList (params) {
    return await this._callApi({
      url: '/general/employment/list',
      method: 'get',
      params,
    })
  }
  // 获取发展历程列表
  async getDevelopmentList (params) {
    return await this._callApi({
      url: '/general/development/list',
      method: 'get',
      params,
    })
  }
  // 获取合作伙伴列表
  async getCooperativeList (params) {
    return await this._callApi({
      url: '/general/cooperative/list',
      method: 'get',
      params,
    })
  }
  // 获取新闻与动态列表
  async getJournalismList (params) {
    return await this._callApi({
      url: '/general/journalism/list',
      method: 'get',
      params,
    })
  }
  // 获取新闻与动态详情
  async getJournalismDetail (params) {
    return await this._callApi({
      url: '/general/journalism/detail/' + params.id,
      method: 'get',
    })
  }
  // 获取行业列表
  async getIndustryTypeList () {
    return await this._callApi({
      url: '/general/industry',
      method: 'get',
    })
  }
  // 获取行业信息详情
  async getIndustryTypeDetail (id) {
    return await this._callApi({
      url: '/general/industry/type/detail/' + id,
      method: 'get',
    })
  }
  // 获取行业案例列表
  async getIndustryList (params) {
    return await this._callApi({
      url: '/general/industry/list',
      method: 'get',
      params,
    })
  }
  // 获取行业案例详情
  async getIndustryDetail (params) {
    return await this._callApi({
      url: '/general/industry/detail/' + params.id,
      method: 'get',
    })
  }
  // 获取页面轮播配置
  async getSlideshowWebList (params) {
    return await this._callApi({
      url: '/general/slideshow/web',
      method: 'get',
      params,
    })
  }
  // dell 续保请求
  async pushRenewal (data) {
    return await this._callApi({
      url: '/general/renewal/add',
      method: 'put',
      data,
    })
  }
  // 留言反馈
  async pushFeedback (data) {
    return await this._callApi({
      url: '/general/feedback/add',
      method: 'put',
      data,
    })
  }
  // 报修请求
  async pushRepair (data) {
    return await this._callApi({
      url: '/general/repair/add',
      method: 'put',
      data,
    })
  }
  // 搜索
  async search (params) {
    return await this._callApi({
      url: '/general/search',
      method: 'get',
      params,
    })
  }
  // 地区选择器数据
  async getLocation () {
    return await this._callApi({
      url: '/general/location',
      method: 'get',
    })
  }
}

export default new ApiHttp