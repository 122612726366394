import { defineStore } from "pinia";
import humblit_logo from '@/assets/humblit_logo.png'
import humblit_menu_logo from '@/assets/humblit_menu_logo.png'
import api from '../api/api'

//参数1：定义一个仓库的唯一id名，Pinia会将所有的容器挂载到根容器；参数2：选项对象
export const useMainStore = defineStore('main', {
  state: () => {//state必须是函数，是避免在服务端渲染时的交叉请求导致的状态数据污染；
    return {
      selfAdaptiveType: 'medium',
      logoUrl: humblit_logo,
      logoUrlLeft: humblit_menu_logo,
      headerOne: {
        list: []
      },
      headerTwo: {
        label: [],
        menu: [],
        menu_mobile: [],
      },
      // 是否获取菜单数据
      isMenu: true,
    }
  },
  getters: {
    //函数接受一个可选参数:state
    getMenus(state) {
      return state.menus
    },
    getSelfAdaptiveType(state) {
      return state.selfAdaptiveType
    }
  },
  actions: {
    setSelfAdaptiveType(selfAdaptiveType) {
      this.selfAdaptiveType = selfAdaptiveType
    },
    // 根据语言加载不同的语言数据
    async switchLanguage(lang) {
      // 发送请求获取菜单列表数据
      const headerOneConfig = {
        'zh-CN': {
          list: [
            { title: '软件', path: '/software' },
            { title: '联系我们', path: '/customer' }
          ]
        },
        'en-US': {
          list: [
            { title: 'Software', path: '/software' },
            { title: 'Contact Us', path: '/customer' }
          ]
        }
      }
      if (this.isMenu) {
        const result = await api.getMenus()
        const headerTwo = result.data
        headerTwo.menu.forEach(item => {
          item.isShow = false
        })
        headerTwo.label.forEach(item => {
          item.isShow = false
        })

        this.headerOne = headerOneConfig[lang] ? headerOneConfig[lang] : headerOneConfig['zh-CN']
        this.headerTwo = headerTwo
        this.headerTwo.menu_mobile = [...headerTwo.label, ...headerTwo.menu]
        this.isMenu = false
      }
    }
  }
})