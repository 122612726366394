import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Index.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/Home.vue'),
        meta: {
          title: '汉博利特(北京)信息技术有限公司',
        }
      },
      {
        path: 'search',
        name: 'search',
        component: () => import(/* webpackChunkName: "home" */ '@/views/search/Search.vue'),
        meta: {
          title: '搜索-汉博利特(北京)信息技术有限公司',
        }
      },
      {
        path: 'customer',
        name: 'customer',
        component: () => import(/* webpackChunkName: "home" */ '@/views/customer/Customer.vue'),
        meta: {
          title: '联系我们-汉博利特(北京)信息技术有限公司',
        }
      },
      {
        path: 'brand',
        name: 'brand',
        component: () => import(/* webpackChunkName: "home" */ '@/views/brand/Brand.vue'),
        meta: {
          title: '合作伙伴-汉博利特(北京)信息技术有限公司',
        }
      },
      {
        path: 'introduce/engineering',
        name: 'engineering',
        meta: {
          headerAllHide: true,
          title: '工程-汉博利特(北京)信息技术有限公司',
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/introduce/Engineering.vue'),
      },
      {
        path: '/introduce/engineering/solution/:id',
        name: 'solution',
        component: () => import(/* webpackChunkName: "home" */ '@/views/introduce/solution/Solution.vue'),
        meta: {
          title: '解决方案-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/introduce/engineering/solution/v2/:id',
        name: 'solutionV2',
        component: () => import(/* webpackChunkName: "home" */ '@/views/introduce/solution/Solution_v2.vue'),
        meta: {
          headerAllHide: true,
          title: '解决方案-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: 'introduce/engineering/construction',
        name: 'construction',
        component: () => import(/* webpackChunkName: "home" */ '@/views/introduce/engineering/Construction.vue'),
        meta: {
          title: 'IT基础架构的设计和实施服务-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: 'introduce/engineering/consulting',
        name: 'consulting',
        component: () => import(/* webpackChunkName: "home" */ '@/views/introduce/engineering/Consulting.vue'),
        meta: {
          title: '技术咨询-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: 'introduce/engineering/IDCServices',
        name: 'IDCServices',
        component: () => import(/* webpackChunkName: "home" */ '@/views/introduce/engineering/IDCServices.vue'),
        meta: {
          title: '数据中心部署实施服务-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: 'introduce/engineering/operation',
        name: 'operation',
        component: () => import(/* webpackChunkName: "home" */ '@/views/introduce/engineering/Operation.vue'),
        meta: {
          title: '网络运维及管理-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: 'introduce/engineering/plan',
        name: 'plan',
        component: () => import(/* webpackChunkName: "home" */ '@/views/introduce/engineering/Plan.vue'),
        meta: {
          title: '规划设计-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: 'introduce/engineering/recycle',
        name: 'recycle',
        component: () => import(/* webpackChunkName: "home" */ '@/views/introduce/engineering/Recycle.vue'),
        meta: {
          title: 'IT资产回收及利用-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: 'introduce/engineering/riskEvaluation',
        name: 'riskEvaluation',
        component: () => import(/* webpackChunkName: "home" */ '@/views/introduce/engineering/RiskEvaluation.vue'),
        meta: {
          title: 'IT风险评估及健康检查-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: 'introduce/engineering/transfer',
        name: 'transfer',
        component: () => import(/* webpackChunkName: "home" */ '@/views/introduce/engineering/Transfer.vue'),
        meta: {
          title: '迁移服务-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: 'introduce/digital',
        name: 'digital',
        meta: {
          headerAllHide: true,
          title: '数字化-汉博利特(北京)信息技术有限公司',
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/introduce/Digital.vue'),
      },
      {
        path: 'introduce/product',
        name: 'product',
        meta: {
          headerAllHide: true,
          title: '产品中心-汉博利特(北京)信息技术有限公司',
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/introduce/Product.vue'),
      },
      {
        path: '/introduce/product/detail/:id',
        name: 'productDetail',
        component: () => import(/* webpackChunkName: "home" */ '@/views/introduce/product/ProductDetail.vue'),
        meta: {
          title: '产品详情-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/industry/v1/:id',
        name: 'industry',
        component: () => import(/* webpackChunkName: "home" */ '@/views/industry/Industry.vue'),
        meta: {
          title: '行业-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/industry/education',
        name: 'education',
        component: () => import(/* webpackChunkName: "home" */ '@/views/industry/Education.vue'),
        meta: {
          title: '教育行业案例-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/industry/finance',
        name: 'finance',
        component: () => import(/* webpackChunkName: "home" */ '@/views/industry/Finance.vue'),
        meta: {
          title: '金融行业案例-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/industry/fabricate',
        name: 'fabricate',
        component: () => import(/* webpackChunkName: "home" */ '@/views/industry/Fabricate.vue'),
        meta: {
          title: '制造行业案例-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/industry/internet',
        name: 'internet',
        component: () => import(/* webpackChunkName: "home" */ '@/views/industry/Internet.vue'),
        meta: {
          title: '互联网行业案例-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/industry/bloc',
        name: 'bloc',
        component: () => import(/* webpackChunkName: "home" */ '@/views/industry/Bloc.vue'),
        meta: {
          title: '祭集团行业案例-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/industry/detail/:id',
        name: 'industryDetail',
        component: () => import(/* webpackChunkName: "home" */ '@/views/industry/detail/Detail.vue'),
        meta: {
          title: '行业案例详情-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: 'about/aboutUs',
        name: 'aboutUs',
        component: () => import(/* webpackChunkName: "home" */ '@/views/about/AboutUs.vue'),
        meta: {
          title: '关于我们-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/about/course',
        name: 'course',
        component: () => import(/* webpackChunkName: "home" */ '@/views/about/Course.vue'),
        meta: {
          title: '发展历程-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/about/partner',
        name: 'partner',
        component: () => import(/* webpackChunkName: "home" */ '@/views/about/Partner.vue'),
        meta: {
          title: '合作伙伴-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/about/news',
        name: 'news',
        component: () => import(/* webpackChunkName: "home" */ '@/views/about/News.vue'),
        meta: {
          title: '公司新闻-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/about/dynamics',
        name: 'dynamics',
        component: () => import(/* webpackChunkName: "home" */ '@/views/about/Dynamics.vue'),
        meta: {
          title: '行业动态-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/about/dynamics/:id',
        name: 'dynamicsDetail',
        component: () => import(/* webpackChunkName: "home" */ '@/views/about/journalism/Detail.vue'),
        meta: {
          title: '公司新闻详情-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/about/news/:id',
        name: 'newsDetail',
        component: () => import(/* webpackChunkName: "home" */ '@/views/about/journalism/Detail.vue'),
        meta: {
          title: '行业动态详情-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/recruitment',
        name: 'recruitment',
        component: () => import(/* webpackChunkName: "home" */ '@/views/recruitment/Recruitment.vue'),
        meta: {
          title: '职业招聘-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/warranty',
        name: 'warranty',
        component: () => import(/* webpackChunkName: "home" */ '@/views/warranty/Warranty.vue'),
        meta: {
          title: 'Dell EMC续保服务-汉博利特(北京)信息技术有限公司',
        },
      },
      {
        path: '/template/v1/:id',
        name: 'templateV1',
        component: () => import(/* webpackChunkName: "home" */ '@/views/template/Template_v1.vue'),
        meta: {
          title: ''
        }
      }
    ]
  },
  {
    path: '/software',
    name: 'software',
    component: () => import(/* webpackChunkName: "software" */ '@/views/software/Software.vue'),
  },
]


const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由拦截
router.beforeEach(async to => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
  await languageSetting(to)
  return
})

// 判断当前语言
async function languageSetting(to) {
  try {
    const { lang } = to.query
    let language = window.localStorage.getItem('language') || null
    if (lang === 'zh-CN' || lang === 'en-US') {
      window.localStorage.setItem('language', lang)
    } else if (!language) { // localStorage 中没有默认值设置
      // 查看当前浏览器的语言设置
      const navigatorLanguage = (navigator.language || navigator.browserLanguage).toLowerCase()
      if (navigatorLanguage !== 'zh-CN' && navigatorLanguage !== 'zh-cn') {
        language = 'en-US'
      } else {
        language = 'zh-CN'
      }
    }
    window.localStorage.setItem('language', language)
  } catch (err) {
    console.error(err)
  }
}

export default router
