// zh - CN.js
export default {
  language: 'zh-CN',
  navigation: {
    searchPlaceholder: '搜索',
    language: {
      english: 'English',
      chinese: '中文'
    }
  },
  cookie: {
    h2_1: 'Cookie偏好中心',
    li_2: '您的隐私',
    li_3: '绝对必要的 Cookie',
    li_4: '性能 Cookie',
    li_5: '功能性 Cookie',
    li_6: '广告 Cookie',
    li_7: '社交媒体 Cookie',
    h2_8: '您的隐私',
    p_9: '本网站可能会在您的浏览器上存储或检索信息,主要以 cookie 的形式。这些信息可能与您、您的偏好或您的设备有关,主要用于使网站按您的预期运行,并为您提供更加个性化的网络体验。我们尊重您的隐私权,因此您可以选择不允许某些类型的 cookie。单击不同的类别标题以了解更多信息。您可以通过移动每个类别中的选择开关来更改我们的默认设置来接受或拒绝我们使用 cookie。但是,阻止某些类型的 cookie 可能会影响您对网站的体验以及我们能够为您提供的服务。',
    p_10: '绝对必要的Cookie',
    active_a: '始终活跃',
    p_11: '这些 cookie 是网站运行所必需的，无法在我们的系统中关闭。它们通常仅针对您所做的相当于服务请求的操作而设置，例如设置您的隐私偏好、登录或填写表格。您可以将浏览器设置为阻止或提醒您有关这些 cookie 的信息，但网站的某些部分将无法运行。这些 cookie 不存储任何个人身份信息。',
    h2_12: '性能Cookie',
    active: '允许',
    inactive: '拒绝',
    p_13: '这些 cookie 使我们能够计算访问量和流量来源,以便我们衡量和改进我们网站的性能。它们帮助我们了解哪些页面最受欢迎和最不受欢迎,并了解访问者如何在网站上移动。这些 cookie 收集的所有信息都是汇总的,因此是匿名的。如果您不允许这些 cookie,我们将不知道您何时访问了我们的网站,也无法监控其性能。',
    h2_14: '功能性Cookie',
    p_15: '这些 cookie 使网站能够提供增强的功能和个性化。它们可能由我们或我们已将其服务添加到我们页面的第三方提供商设置。如果您不允许这些 cookie,则这些服务中的部分或全部可能无法正常运行。',
    h2_16: '广告Cookie',
    p_17: '这些 cookie 可能由我们的广告合作伙伴通过我们的网站设置。这些公司可能会使用它们来建立您的兴趣档案,并在其他网站上向您展示相关广告。它们不直接存储个人信息,而是基于唯一标识您的浏览器和互联网设备。如果您不允许这些 cookie,您将体验到针对性较低的广告。',
    h2_18: '社交媒体Cookie',
    p_19: '这些 cookie 由我们添加到网站的一系列社交媒体服务设置,使您能够与您的朋友和网络分享我们的内容。他们能够在其他网站上跟踪您的浏览器并建立您的兴趣档案。这可能会影响您在访问的其他网站上看到的内容和消息。如果您不允许这些 cookie,您可能无法使用或查看这些共享工具。',
    btn_20: '确定我的选择',
    btn_21: '全部拒绝',
  },
  home: {
    presentation_h4_top1: '2014',
    presentation_span_top1: '创立',
    presentation_h4_top2: '50+',
    presentation_span_top2: '专业技术人员',
    presentation_h4_top3: '20+',
    presentation_span_top3: '覆盖区域',
    presentation_h4_top4: '500+',
    presentation_span_top4: '大型客户',
    presentation_h4_top5: '20+',
    presentation_span_top5: '专利技术',
    product_h2_top1: '您的需求就是我们的产品',
    product_p_top2: 'IT资产全生命周期管理',
    product_h2_top7: '产品详情视频',
    product_p_top8: '我们的产品品质,亲眼去见证',
    regard_p_top1: '以用户为中心和追求卓越的精神,为合作伙伴以及客户提供全面的',
    regard_p_top2: 'IT解决方案服务以及基础软硬件设施,',
    regard_p_top3: '综合运用各类先进技术以及最直接的渠道资源,帮助企业在深度互联网化和人工智能化的时代大潮中',
    regard_p_top4: '获得强大竞争优势',
    product_video_title1: 'DELL EMC DSS 8440借助灵活、易于扩展的计算、网络和存储, 打造一个未来无忧的敏...',
    product_video_title2: '浪潮NF5288M5AI计算加速器 为了提升计算效率、满足多样化AI场景需求,浪潮NF52...',
    product_video_title3: 'Dell R730是一款可扩展的服务器,实现了内存、存储、处理能力和GPU的资源整合...',
    product_video_title4: 'NVIDIA DGX-2 是 NVIDIA 首款 2-petaFLOPS 系统,它整合了 16 个完全互联的 GPU,使深度学...',
    industry_application: '行业应用',
    industry_application_p: '质量构建与客户的信任和IT服务的价值',
    product_h: '我们的特色合作伙伴',
    product_p: '我们与世界级供应商网络合作，为我们的客户提供支持',
    digitalOffering: {
      title1: 'HPC',
      rightTitle1: 'HPC解决方案',
      leftParagraph1_top1: '支持个性化需求定制',
      rightParagraph1_top1: 'AI人工智能,深度学习,CAE仿真,高性能加速计算 ,虚拟化,高性能网络',

      title2: 'SAP HANA',
      rightTitle2: 'SAP HANA 解决方案',
      leftParagraph2_top1: '支持个性化需求定制',
      rightParagraph2_top1: 'SAP HANA是一个软硬件结合体,提供高性能的数据查询功能,用户可以直接对大量实时业务数据进行查询和分析,而不需要对业务数据进行建模、聚合等。用户拿到的是一个装有预配置软件的设备。',
      rightParagraph2_top2: '软件方面,SAP HANA的内存数据库（SAPIn-MemoryDatabase,IMDB）是其重要组成部分,包括数据库服务器(In-MemoryDatabaseServer)、建模工具（Studio）和客户端工具（ODBO、JDBC、ODBC、SQLDBC等）。SAP HANA的计算引擎（ComputingEngine）是其核心,负责解析并处理对大量数据的各类CRUDQ操作,支持SQL和MDX语句、SAP和non-SAP数据。比较显而易见的一点是,SAP HANA计算引擎要快速处理用户复杂的查询请求,快速返回查询结果。SAP内存数据库的数据并不是只在内存里,也会不停写到硬盘里,这就用到复制服务器ReplicationServer,包括Log-based,Trigger-based和ETL-based。这些复制服务器需要用到SybaseReplicationServer、SybaseReplicationServerAgent、SybaseAdaptiveServerEntERPrise(AES,适用性服务器)等,以及SAP HANALoadController和BODataServices。',

      title3: '大数据分析',
      rightTitle3: '大数据分析 解决方案',
      leftParagraph3_top1: '支持个性化需求定制',
      rightParagraph3_top1: 'HANA是一个软硬件结合体,提供高性能的数据查询功能,用户可以直接对大量实时业务数据进行查询 和分析。用户拿到的是一个装有预配置软件的设备。至于HANA的云服务,只是对用户而言可以在不购 买相关硬件的情况下享受HANA的高性能,而HANA云服务的背后还是需要更高性能的硬件支撑的',

      title4: '私有视频云',
      rightTitle4: '私有视频云 解决方案',
      leftParagraph4_top1: '支持个性化需求定制',
      rightParagraph4_top1: 'HANA是一个软硬件结合体,提供高性能的数据查询功能,用户可以直接对大量实时业务数据进行查询 和分析。用户拿到的是一个装有预配置软件的设备。至于HANA的云服务,只是对用户而言可以在不购 买相关硬件的情况下享受HANA的高性能,而HANA云服务的背后还是需要更高性能的硬件支撑的',

      title5: '商业智能',
      rightTitle5: '商业智能 解决方案',
      leftParagraph5_top1: '支持个性化需求定制',
      rightParagraph5_top1: 'HANA是一个软硬件结合体,提供高性能的数据查询功能,用户可以直接对大量实时业务数据进行查询 和分析。用户拿到的是一个装有预配置软件的设备。至于HANA的云服务,只是对用户而言可以在不购 买相关硬件的情况下享受HANA的高性能,而HANA云服务的背后还是需要更高性能的硬件支撑的',
    },
  },
  digital: {
    weMedia: '新媒体',
    rests: '其他',
    taobao: '淘宝店',
    applet: '二手设备小程序',
    douyinStore: '华为抖音店'
  },
  solution_v2: {
    generalize: '概述',
    merit: '优点',
    scheme: '解决方案',
    resource: '资源',
    followUp: '后续步骤'
  },
  recycle: {
    h2_1: '硬件维护服务 - 过期设备报废及回收',
    p_2: 'IT资产审计、回收、IT资产报废处理 - 完整IT生命周期的资产管理方案',
    h2_3: 'IT 设备报废及回收',
    p_4: '汉博利特为客户提供符合法规的、生态环保的电子产品回收服务，帮助客户管理电子设备的重新利旧。汉博利特提供免费的IT资产评估服务，根据实际情况提供数据擦除以及报废服务。',
    h4_5: '700',
    p_6: '汉博利特的电子产品回收以及报废服务适用于几乎所有的老旧IT设备，包括电脑、交换机、服务器、存储等',
    h4_7: '95%',
    p_8: '遵循法规和信息安全要求对报废设备进行安全的数据擦除，之后汉博利特工程师揽收设备后进入报废流程。',
    h4_9: '80%',
    p_10: '对老旧设备进行安全报废，通过利旧或者产品的更新换代，节省能源支出，减少二氧化碳的排放。',
    h2_11: '在IT设备报废和回收领域有7年的行业经验',
    p_12: '通过维护、再利用和回收来延长IT设备的生命周期，是一种直接的碳收益，您可以节省高达60%的二氧化碳排放。硬件处理服务的主要目的是让二手IT设备产生价值，为IT备件的再利用创造一个可持续的解决方案，并将剩余的回报全部投入到新技术和替代方案中。',
    button_13: '联络IT顾问',
    h2_14: 'Humblit设备回收流程',
    p_15: '随着向云过渡的行业趋势，本地过时的IT资产，如服务器、网络设备、硬盘、网络存储和其他数据中心设备，Humblit与政府授权的回收公司合作，提供涵盖IT设备仓储和环保的数据处理服务，提供拆除、包装、物流运输、技术数据擦除和IT报废证书等。',
    h2_16: 'IT 资产的审计',
    p_17: '汉博利特使用专业的审计软件管理所有老旧设备，辨识即将报废的资产和信息，为客户提供报废信息的收集、分析和追溯服务。',
    h2_18: '数据的擦除和设备销毁',
    p_19: '汉博利特提供相关数据的数据磁盘消磁、物理损坏以及硬盘粉碎服务，这些服务可以在客户现场或者离岸处理中心操作完成。',
    h2_20: 'IT报废综合报告',
    p_21: '在整个电子垃圾报废流程完成之后，汉博利特为客户提供完整的报废服务实施报告，详细呈现已经报废设备的照片和序列号等。',
    h2_22: '有关更多的硬件维护服务内容',
    button_23: '联系客户经理',
    h4_24: '安全的数据擦除',
    p_25: '遵循当地政府的电子垃圾管理法，以及客户的ITAD政策，汉博利特帮助客户完成数据擦除以及有效的老旧设备报废服务',
    h4_26: 'IT硬件设备搬迁服务',
    p_27: '汉博利特安排了货车、工程师以及相关保险，帮助我们完成设备的下架、包装、盘点以及新办公地点的安装和测试服务',
    h4_28: 'IT硬件设备租赁服务',
    p_29: '在客户业务高峰期或者临时业务场所，汉博利特提供的设备租赁服务，并提供现场工程师在活动期间进行值守和支持。',
    h4_30: '每年IT设备回收的规模',
    p_31: 'ISO 9001: 2015 - ISO 14001: 2015 质量和环境保护合规管理',
    p_32: '9000',
    p_33: '中国大陆',
    p_34: '780',
    p_35: '香港和台湾',
    p_36: '1267',
    p_37: '新加坡',
    p_38: '105',
    p_39: '马来西亚',
    h2_40: '汉博利特的客户',
    p_41: '汉博利特帮助我们将老旧设备进行合规回收，不仅仅是一次性去除无用的IT硬件设备，更重要的是帮助我们将IT设备的残值用于新技术、新设备的投入，有效的节省了我们的开支。我们也认识到，汉博利特通过在现场以及场外设备帮助我们完整的做完数据擦除，并进一步完成了所有存储介质的消磁以及粉碎工作，这对于我们上市公司的信息安全管控提供了有力的技术支持。',
  },
  riskevakuation: {
    h2_1: 'Risk Profiler 风险概况剖析服务',
    p_2: 'IT 风险评估是一个分析 IT 系统潜在威胁和漏洞的过程，以确定如果某些事件发生时您可能会遭受的损失。其目标是帮助您以合理的成本实现最佳安全性',
    span_3: '能给您最专业的IT风险概况评估剖析服务',
    h2_4: 'IT 风险概况剖析是加强安全性的关键第一步',
    span_5: '什么是风险概况剖析 我们为什么需要？',
    p_6: '每个成功的信息安全计划都需要评估现有系统和正在实施的新 IT 风险管理流程。质疑任何新软件应用程序的安全状况并在全面实施之前对其可能存在的漏洞进行审查是有益的。当新的合规标准或监管措施浮出水面时，重新评估和验证 IT 系统和资产也是一项有价值的练习。所有这些都是有效的 IT 风险管理计划的一部分。',
    h2_7: '风险概况剖析原则',
    li_8: '1. 风险分析必须考虑可能受此风险影响的所有利益相关者的需求。',
    li_9: '2. 风险必须降至监管机构和潜在相关方认为可接受的程度。',
    li_10: '3. 保障措施的负担不得超过它们为自己辩护的威胁。',
    h2_11: '风险评估实践',
    li_12: '1. 风险分析考虑了此类风险产生巨大影响的可能性。',
    li_13: '2. 相同的标准用于评估风险和保障措施，以便它们具有可比性。',
    li_14: '3. 影响和可行性评级具有定性方面，以简明的方式指定相关方、监管机构和审查机构。',
    li_15: '4. 影响和可行性评级是从比较所有评估的风险、预防措施和风险接受要求的数值计算得出的。',
    li_16: '5. 影响的定义保证了对一个群体的伤害程度等同于对其他群体的伤害程度。',
    li_17: '6. 影响的定义应明确限制适合所有各方的维度和不适合的维度。',
    li_18: '7. 影响地址的定义；组织的任务或效用，以澄清组织和其他人是否涉及风险，组织的自利关注，以及组织保护他人免受伤害的义务。',
    li_19: '8. 为了分析现有的控制和推荐的保护措施，风险分析取决于护理质量。',
    li_20: '9. 风险由使用数据确定威胁和预防措施的主题专家进行评估。',
    li_21: '10. 风险评估不能衡量所有可能的风险。随着时间的推移，为了定义和解决进一步的威胁，需要重新进行风险评估。',
    h2_22: 'IT风险评估方法',
    p_23: 'IT 风险评估是一个分析 IT 系统潜在威胁和漏洞的过程，以确定如果某些事件发生时您可能会遭受的损失。其目标是帮助您以合理的成本实现最佳安全性。',
    p_24: '评估不同类型的 IT 风险有两种流行的方法：',
    a_25: '定量 和 定性风险分析',
    h2_26: 'IT风险评估的两种方法',
    div_27: '定量IT风险评估',
    div_28: '定性IT风险评估',
    h4_31: '在 IT 风险评估中使用不同类型的信息',
    p_32: '通常，最好使用混合方法进行 IT 风险评估，结合定量和定性分析的要素。',
    p_33: '您可以使用定量数据来评估资产价值和预期损失，还可以让您的企业中的人参与进来，以获得他们的专家洞察力。这可能需要时间和精力，但与每种方法单独提供的数据相比，它也可以更好地了解风险和更好的数据。',
  },
  transfer: {
    h2_1: 'IT 迁移服务',
    h2_2: '我们的迁移服务为您提供专业知识，以缩短上市时间并更快地从您的新平台或升级平台中获益',
    p_3: '您要迁移到 Microsoft 365 吗？升级到最新版本的 Microsoft Exchange？搬到托管设施？变化总是有风险的。',
    p_4: '我们的移民服务将带来我们的专业知识和经验，以确保将风险降至最低并使项目尽可能顺利进行。',
    h2_5: '获取更多信息',
    p_6: '详细了解我们的评估服务：查看我们的服务目录，或联系我们了解更多信息。',
    button_7: '降低我的迁移风险',
    h2_8: '迁移服务',
    p_9: '云迁移',
    p_10: '数据中心迁移',
    p_11: '微软交换',
    p_12: '服务器迁移',
    p_13: '存储和数据迁移',
    p_14: '微软 365 迁移',
    h2_15: '云迁移服务',
    span_16: '云技术的采用改变了许多组织的格局。问题通常不是组织是否应该迁移到云，而是应该迁移什么以及如何迁移。组织必须对他们的迁移进行基准测试，以证明时间、迁移过程以及网络和存储连接。在验证迁移的预定义应用程序集上试行迁移也很重要。',
    p_17: '您是否已决定将服务迁移到云端？您的云战略是私有云、公共云还是混合云？Zunesis 为这些模型中的任何一个提供云迁移服务。我们通过分析您当前的环境和云实施策略来启动此过程。我们从云研讨会开始，作为构建用例、策略、优先级和建议的第一步。我们包括迁移规划、管理和执行。',
    h2_18: '可交付成果',
    p_19: '基础设施当前环境布局的文档',
    p_20: '迁移策略和项目计划',
    p_21: '风险分析和缓解计划',
    p_22: '全过程项目管理',
    p_23: '完成应用程序和虚拟机到云端的迁移',
    h2_24: '好处',
    p_25: '有组织有计划的迁移',
    p_26: '减轻由中断或损坏的数据引起的风险',
    p_27: '在迁移期间最大化可用性',
    p_28: '减少执行时间和对业务的影响',
    h2_29: '数据中心迁移服务',
    span_30: '我们的许多客户希望摆脱数据中心业务，并希望迁移到托管或共享位置。这在许多领域带来了挑战，并给他们的关键数据带来了风险。如果未正确完成此操作，计划外停机和长时间中断会对业务产生负面影响。',
    p_31: '许多客户正在重新评估他们的数据中心战略，并选择退出“数据中心业务”。这给大多数客户带来了挑战和风险。Zunesis 参与了许多数据中心迁移，包括进出托管设施。鉴于当今数据中心的复杂性，您需要以前做过这件事的合作伙伴。我们可以帮助规划和管理迁移，以帮助最大程度地降低所涉及的风险。',
    h2_32: '可交付成果',
    p_33: '当前环境和资产报告的文件',
    p_34: '迁移策略和项目计划',
    p_35: '风险分析和缓解计划',
    p_36: '全过程项目管理',
    h2_37: '好处',
    p_38: '有组织有计划的迁移',
    p_39: '减轻风险',
    p_40: '系统准备和卸载',
    p_41: '系统重装、开机及系统检查',
    p_42: '最大限度地减少中断',
    h2_43: '微软交换服务',
    span_44: '我们的许多客户都在使用旧版本的电子邮件系统，需要迁移。随着更新版本的 Exchange 和 Microsoft 365 的推出，现在是迁移到这些新产品的时候了。这可能是一个昂贵且耗时的项目。',
    p_45: '设计、实施和迁移到新版本的 Microsoft Exchange 可能是一项复杂而困难的任务。Microsoft 在 Exchange 中引入了许多新功能，随着 Microsoft 365 的推出，我们的许多客户都选择将电子邮件移出云端。Zunesis 拥有丰富的经验，可以帮助设计和调整环境规模、构建新环境以实现弹性、集成到 Active Directory 中并实施解决方案。我们还可以帮助将现有用户和电子邮件数据迁移到这个新环境或 Microsoft 365，具体取决于您的策略和方向。',
    h2_46: '可交付成果',
    p_47: '产生高度可用的 Microsoft Exchange 环境',
    p_48: '记录对业务的影响',
    p_49: '项目计划和迁移过程',
    p_50: '新Exchange环境技术图',
    h2_51: '好处',
    p_52: '更有效地利用 IT 资产存储架构图',
    p_53: '提高正常运行时间和数据可用性',
    p_54: '降低总体成本',
    p_55: '更快地迁移到新技术',
    h2_56: '服务器迁移服务',
    span_57: '我们的许多客户都有较旧的服务器，需要迁移。结合更新的硬件，迁移可以降低成本并改进 SLA。',
    p_58: '无论您是需要迁移到云还是只是迁移到更新的服务器技术，Zunesis 都有专业知识和经验来提供规划、测试和迁移服务，从而为您的公司节省时间和金钱。服务器迁移不仅仅是移动数据。我们的流程将帮助您规划和执行这些迁移，同时将风险降至最低。',
    h2_59: '可交付成果',
    p_60: '实现平稳、干净的迁移',
    p_61: '迁移所需的技术变更时间表',
    p_62: '技术评估、设计和风险分析',
    p_63: '记录的项目计划和迁移过程',
    h2_64: '好处',
    p_65: '提高正常运行时间和数据可用性',
    p_66: '降低成本',
    p_67: '由于服务器过时而导致的安全漏洞较少',
    h2_68: '存储和数据迁移服务',
    span_69: '新技术的采用使存储和数据迁移成为当今企业的一项要求。在迁移过程中最大限度地提高可用性和性能是任何迁移的必要条件。这一切都必须在最大限度地降低风险和成本的同时完成。',
    p_70: '存储和数据迁移对任何客户来说都是一项挑战。从较旧的技术迁移到较新的技术或在不同的存储基础设施之间迁移通常是一项高风险、高成本的工作。这是让客户采用新技术必须发生的必要过程。Zunesis 可以帮助您降低风险并更有效地管理您的迁移过程。',
    h2_71: '可交付成果',
    p_72: '全过程项目管理',
    p_73: '记录的迁移策略',
    p_74: '风险分析和缓解计划',
    p_75: '迁移策略和项目计划',
    p_76: '当前存储环境布局的文档',
    h2_77: '好处',
    p_78: '减少执行时间和对业务的影响',
    p_79: '在迁移期间最大化可用性',
    p_80: '减轻由中断或损坏的数据引起的风险',
    p_81: '有组织有计划的迁移',
    h2_82: '微软 365 迁移',
    span_83: 'Microsoft Office 应用程序是企业中最知名的一些工具。员工很少在没有 Word 或 Excel 的工作场所工作，超过 10 亿人在 Outlook 中安排他们的一天。',
    p_84: 'Zunesis 配备齐全，可帮助您的企业以具有成本效益、高效且无缝的方式迁移到 Microsoft 365，从而转变您的组织开展业务的方式并帮助您获得 Microsoft 365 的诸多好处。',
    h2_85: '可交付成果',
    p_86: '标准化Office版本并消除手动修补或升级的负担',
    p_87: '选择最适合的Microsoft 365许可模式',
    p_88: '确定您的特定需求和迁移类型',
    p_89: '在您的环境中安装Microsoft 365订阅和身份验证',
    p_90: '负责地退役不再需要的本地资产',
    p_91: '将桌面Office应用程序升级到最新版本',
    h2_92: '好处',
    p_93: '在任何设备上与桌面，移动和基于浏览器的PC, Mac, iPhone和Android应用程序无缝地工作。',
    p_94: '使用OneDrive云存储来同步、组织和共享文件。',
    p_95: '灵活的授权模式，没有前期成本或合同。每个员工最多使用五台设备。',
    h2_96: '立即安排您的IT系统健康检查',
    button_97: '联系我们'
  },
  about_us: {
    span_1: '市场认可的IT服务支持计划和模型',
    h2_2: '汉博利特 - 您亚洲的IT服务部门',
    p_3: '多语言IT服务台技术支持,24x7技术支持,覆盖整个亚洲,管理型IT服务实现IT服务的自动化严格的技术审计和技术实施规范',
    button_4: '联系IT服务资讯顾问',
    h2_5: 'IT 服务能力和覆盖范围',
    button_6: '更多区域覆盖',
    h4_7: '成立于2014年',
    p_8: '汉博利特于2014年成立的时候,仅有3名员工,开始致力于管理型IT服务。经过10多年的发展,已将高质量IT服务通过流程驱动亚洲绝大部分国家,7天24小时交付IT服务',
    h4_9: '106+ 合格工程师',
    p_10: '汉博利特FSS认证工程师专注于高质量的IT服务和解决方案,通过评估和审计,为客户提供终端电脑、服务器、存储、网络设备以及云计算的解决方案和长期技术支持',
    h4_11: '亚洲范围内IT技术支持',
    p_12: '24 x7 的IT服务台作为汉博利特IT服务运营中心受理并管理所有客户的IT服务请求,遵循严格服务等级,联动FSS现场工程师和高级别技术支持团队,为亚洲客户交付技术支持',
    h2_13: '客户需要我们,汉博利特立刻支持',
    h4_14: '汉博利特交付高质量、体系化的IT技术支持服务',
    button_15: 'IT服务支持方案',
    h4_16: '汉博利特有竞争力的技术优势有：',
    li_17: '资源管理平台: RMP平台管理者96个城市的工程师资源.',
    li_18: '服务运营团队: ITIL/PMP认证的合格服务经理',
    li_19: 'IT服务工具: 服务台系统,网络及系统监控工具,备件管理平台,资源管理平台,商业密码管理工具,配置管理平台',
    li_20: '知识和经验: 专注于在亚洲交付高质量的IT服务和用户体验',
    h2_21: '汉博利特公司介绍',
    p_22: '汉博利特（北京）信息技术有限公司 (简称 Humblit) 是一个IT管理型服务及解决方案提供商,始终专注于为客户提供快速服务响应、高级技术方案、亚洲区域覆盖的技术支持,以及成本节约型的IT服务计划。',
    button_23: '联系我们',
    p_24: '汉博利特作为管理型IT服务,秉承“平台+服务”的理念,旨在实现IT服务的数据可视化。RSCM汉博利特资源管理团队以大数据和AI为驱动,在亚洲各个国家和城市为客户交付IT基础架构相关的咨询、实施、部署以及长期技术支持,帮助客户优化投资回报率ROI,以及降低IT的总体拥有成本TOC. ',
    p_25: '目前汉博利特拥有106+ 专业项目经理以及服务管理经理。每天IT服务运营中心(SCC)以及网络运营中心(NOC)调度超过1500+的认证合同工程师,管理着超过60万台IT设备,为客户提供长期技术支持,通过分类IT服务请求、事件以及告警,持续优化并提升客户服务体验和质量。',
    p_26: '汉博利特IT管理型服务模型,为客户提供IT综合评估和审计,以此为基础为客户设计最佳的IT基础架构,提升IT日常运营的效率、信息安全以及可靠性,满足客户核心业务的目标和业务连续性目标。 汉博利特FSS以及GSD服务团队为客户提供端到端的整体性IT服务解决方案,通过计划、实施以及长期技术支持构建可衡量、正向的IT投资回报,为客户提供高质量IT服务体验。',
    button_27: '亚洲区域虚拟IT部门',
    p_28: '库房服务以及备件',
    p_29: '硬件维护和支持服务',
    p_30: '云计算解决方案',
    p_31: '办公室IT建设服务',
    p_32: '24 x7IT服务台',
    p_33: '多语言技术支持',
    p_34: '网络运维及支持服务',
    p_35: '专业的技术实施服务',
    h2_36: '理念 & 愿景',
    p_37: '在亚洲交付高质量、最佳用户体验的技术支持服务',
    h4_38: '高效的执行力',
    h4_39: '团队协作',
    h4_40: '员工价值',
    h4_41: '最佳的客户体验',
    h2_42: '可靠的IT服务团队保障技术支持的客户满意度',
    p_43: '汉博利特亚洲采用了RMM和PSA支持工具,如ATRA、Site 24 x 7、Manage Engine MSP Plus Help Desk和IT-GLUE等,将古老的监控和维护程序从被动转为主动,从被动转为预测,从手动转为自动。在汉博利特,我们正在思考如何利用人力和现代技术来提高您的生产力,并降低您在IT基础设施和维护服务上的投资。汉博利特的Hypercare管理式IT服务可以保证您的IT运行和您的用户得到支持,因此您可以专注于您的IT战略和业务增长。您将受益于：',
    a_44: '优化的IT性能: ',
    p_45: '积极主动的IT管理方法使您的网络和系统以最佳性能运行。',
    a_46: '终端用户支持: ',
    p_47: '通过专业的、基于中国的服务台,汉博利特将确保您的IT用户获得完全合格的专业服务。',
    a_48: '成本效益: ',
    p_49: '通过利用内部票务和监控系统、最佳实践流程和资源,节省IT管理费用。',
    a_50: '畅通无阻: ',
    p_51: '有了汉博利特IT托管服务,您的IT环境就可以放心了。',
    h4_52: '24 小时, 365 天技术支持',
    p_53: '汉博利特关注客户核心业务的连续性目标,关注IT服务连续性对客户业务运营的重要性,承诺7x24的高等级技术支持。',
    h4_54: '快速响应的IT支持计划',
    p_55: '汉博利特为客户提供一点受理,亚洲服务的模型。所有团队成员严格遵循服务等级协议(SLA),按照业务的重要性提供快速响应。',
    h4_56: '工程师以及认证',
    p_57: '为客户提供技术支持的工程师均为认证工程师,通过了思科、华为、微软、ARUBA, JUNIPER, HP, IBM, DELL等技术认证。',
  },
  userCase: '用户案例',
  partner: '合作伙伴',
  companyName: '汉博利特（北京）信息技术有限公司',
  establish: '2014年成立至今',
  connectUs: '让我们与您联系',
  connectUsDesc: '填写您的信息,之后我们的专属业务顾问会很快与您联系。',
  companyNews: '公司新闻',
  industryTrends: '新闻动态',
  newestNews: '最新动态',
  product: {
    server: '高性能服务器',
    storage: '高性能存储',
    network: '高性能网络传输',
    mounting: '高性能周边配件',
    notOnlne: '暂时还未上线产品',
    presentation: '产品介绍',
    function: '功能特性',
    specification: '技术规格'
  },
  industry: {
    bloc: '集团行业',
    education: '教育行业',
    fabricate: '制造行业',
    finance: '金融行业',
    internet: '互联网行业',
  },
  engineering: {
    solution: '解决方案',
    technical: '技术服务',
    warranty: '在线保修',
    mixedCloud: '混合云解决方案',
    website: '网站解决方案',
    industry: '行业解决方案',
    engineer: '工程解决方案',
    technicalDescribe: 'Humblit总结出一套用于规划信息系统基础架构的方法论。该方法论当在合理规划和安排各信息化基础设施,使之形成良好的环境,让各种业务解决方 案、应用系统和数据都能不受约束,同时保证有效配合,以确保基础架构能够快速、稳健的支撑业务需求,帮助企业远离技术和业务迅速变化所带来的复杂性。',
    introductionScheme: '方案介绍',
    solutionArchitecture: '方案架构',
    relevantRecommendation: '产品推荐',
    schemeRecommendation: '方案推荐',
    productInformation: '产品信息',
    contactAddress: '联系地址',
    product: '产品',
    selectProduct: '请选择产品',
    productType: '产品类型',
    selectProductType: '请选择产品类型',
    otherRequirement: '其他需求',
    visitTime: '上门时间',
    selectVisitTime: '请选择上门时间',
    planningProgram: '规划设计',
    implementation: 'IT基础架构的设计和实施服务',
    operationsManagement: '网络运维及管理',
    IDBserve: '数据中心部署实施服务',
    technologyConsulting: '技术咨询',
    plan: {
      h2_1: 'IT基础架构咨询概述',
      P_1: 'humblit总结出一套用于规划信息系统基础架构的方法论。该方法论当在合理规划和安排各信息化基础设施,使之形成良好的环境,让各种业务解决方 案、应用系统和数据都能不受约束,同时保证有效配合,以确保基础架构能够快速、稳健的支撑业务需求,帮助企业远离技术和业务迅速变化所带来的复杂性。',
      h2_2: '服务概述',
      div_2: '以往企业构建基础架构时往往以应用开发项目为单元进行建设,项目之可相互独立,形成一个个孤立的IT,时间一长,数据中心设备混乱,部署不统一,资源不共享,单个系统可性差,IT投资居高不下,基 础架构服务能力逐步下降。即使虚拟化软件广泛使用的情况下,也因为不同时期建设的原因,造成了一个个孤立的资源池,总体来看,产生上述问题的原因主要在于,企业对基础架构没有长远规划,业务部门、开发部门和运部门没得统一的架构指导,只从自己的视角出发看待IT基梁构。特别是企业新建数据中心或者建设容灾中心的时候,这个问题往往就会集中爆发,如何在合理的IT投资下,建设 稳健、安全、快捷部署,易于维护的基码构平台,既能支持现有的应用部署, 又能支持未来业务的发展是每一位T管理者最为头痛的问题.为了改善这一现状,汉博利特拥有多年的丰富经验,总结了一套用 于规动信息系统基础架构的方法论,这些基础设施包括网络、主机存储、操作系统、故据车应到化,运维管理平台等,该方法论旨在合理规划和安排各项信息化基础设施,使之形成良好IT环境,让各种业务解决方室,应用系统和数据都能不受约束地在其上实现有效配台,以确保基础架构能够快速、稳健的支撑业务需求,并且利用先进的技术,从而帮助企业解决业务迅速变化所而带来的复杂业务需求。',
      h2_3: '服务内容',
      div_3_top1: '1.基础架构平台的总体规划',
      div_3_top2: '2.数据中心功能定位和布局规划',
      div_3_top3: '3.数据中心双活/多活规划',
      div_3_top4: '4.网络架构规划',
      div_3_top5: '5.安全体系架构规划',
      div_3_top6: '6.IT运维服务体系架构规划',
      div_3_top7: '7.存储及SAN架构规划',
      div_3_top8: '8.云架构规划',
      div_3_top9: '9.备份架构规划',
      div_3_top10: '10.容灾架构规划',
      div_3_top11: '11.中间件和数据库规划',
      div_3_top12: '12.迁移方案',
      div_3_top13: '13.实施路径和项目建议',
      div_3_top14: '14.IT预算和采购建议',
      h2_4: '其他优势',
      h4_4_1: '推动业务价值',
      p_4_1_top1: '强于竞争对手的应用系统',
      p_4_1_top2: '灵活的,便于并购和重组的基础架构集群',
      p_4_1_top3: '安全稳定的业务流程支持体系',
      h4_4_2: '提高系统可用性',
      p_4_2_top1: '标准的高可用系统架构',
      p_4_2_top2: '清晰的系统构建模块',
      p_4_2_top3: '快速的故障排除,主动式的问题管理',
      h4_4_3: '控制成本',
      p_4_3_top1: '清晰的投入/产出报告',
      p_4_3_top2: '有针对性的,全生命周期的投资方法',
      h4_4_4: '全面提升IT团队',
      p_4_4_top1: '明确职能,提升协作,专业化,系统化,标准化',
      p_4_4_top2: '从技术骨干,转变为业务部部门不可缺少的顾问和伙伴',
      h2_5: '解决方案',
      h2_6: '用户案例',
    },
    construction: {
      h2_1: '商业挑战',
      p_1: '在互联网时代 , 企业的传统信息系统基础架构面临着巨大挑战',
      h4_1_top1: '投入成本高',
      p_1_top1: '基础架物随各应用系统上线而分批建设,彼此之间呈烟图式结构,且各子系统均按照业务峰值流量建设。',
      h4_1_top2: '资源利用率低',
      p_1_top2: '基础架构整体利用率普遍低于30%,各应用之间无法有效地实现资源共享,无法实现资源的快速回收和再利用。',
      h4_1_top3: '弹性不足灵活度差',
      p_1_top3: '业务的变化越来越快,基础梁构无法根据应用需要实现快速调整弹性扩容,延误应用上线和业务发展的良机。',
      h4_1_top4: '自动化运维程度低',
      p_1_top4: '统一监控管理和自动化运维工具缺失或部署不到位,导致故谊恢复时间长、运维效率低,用户满意度不高, I部门把绝大部分精力消耗在故障排查和救火,而不是用于T创新和对业务的有效提升。',
      h4_1_top5: '业务连续性保障不足',
      p_1_top5: '随着业务对T依赖程度越来越高, 业务连续性的保障变得越来越重要。传统的容灾建设已经落伍,数据中心双活和跨数据中心高可用逐渐成为对新一代基础梁构的需求。',
      h2_2: '实施内容',

      div_2_top1_1: '政府/公共事业',
      div_2_top1_2: '医疗',
      div_2_top1_3: '金融',
      div_2_top1_4: '电信',
      div_2_top1_5: '能源',

      div_2_top2_1: '互联网',
      div_2_top2_2: '零售',
      div_2_top2_3: '交通',
      div_2_top2_4: '教育',
      div_2_top2_5: '媒体',

      div_2_top3_1: '基础架构集成服务',
      div_2_top3_2: '基础架构运维服务',

      div_2_top4_1: '数据中心基础架构集成',
      div_2_top4_2: '园区基础架构集成',
      div_2_top4_3: '数据中心运维服务',
      div_2_top4_4: '园区基础架构运维服务',

      div_2_top5_1: '多点大范围集成部署',
      div_2_top5_2: 'IAAS服务',

      div_2_top6_1: '融合控制与资源管理层',

      div_2_top7_1: 'SIM',
      div_2_top7_2: '虚拟化',
      div_2_top7_3: '云计算',
      div_2_top7_4: '统一监控管理',
      div_2_top7_5: '自动化部署',

      div_2_top8_1: '基础支撑层',

      div_2_top9_1: '网络',
      div_2_top9_2: '服务器',
      div_2_top9_3: '存储',
      div_2_top9_4: '超融合系统',
      div_2_top9_5: '安全',
      div_2_top9_6: '基础软件',

      h4_3_top1_1: '基础支撑层',
      p_3_top1_2: '为信息系统基础架构的搭建提供资漂支撑, 包括各种组件:网络、服务器、存储、超融合系统、安全产品、基础软件等',
      h4_3_top2_1: '融合控制与资源管理',
      p_3_top2_2: '将基础架构资源进行池化和统一调度管理。 通过SDN技术实现网络资源的虚拟化和集中控制;通过主机虚拟化和云计算技术实现 VM的创建、迁移、克隆和快照等管理功 能;通过统一的资源管理系统实现网络、计 算、存储、数据库、安全等各类资源的申 请、管理以及自动化部署,并且可以提供 完善的目服务门户',
      h4_3_top3_1: '基础架构集成服务',
      p_3_top3_2: '为企业的新信息系统基础架构建设提供从 咨询、设计到实施的整体集成服务。业务内容包括：数据中心基础架构集成(新建、 整合、迁移),园区基础架构集成(新建、改 造) ,多点大范围集成部署(标准化设计、试 点、大规模推广)',
      h4_3_top4_1: '基础架构运维服务',
      p_3_top4_2: '为企业客户提供基础架构专业运维服务, 含运维流程的梳理、运维工具平台的搭建、服务台及一、二线支持团队建设。 业务内容包括：数据中心运维服务、 园区基础架构运维服务、IAAS服务 供应',
    
      h2_4: '服务优势',
      span_4_top1: '优质产品线',
      span_4_top2: '丰富项目集成经验',
      span_4_top3: '业内口碑服务商',
      span_4_top4: '专业项目管理团队',
      span_4_top5: '一站式项目交付服务',
      p_4: '将基础架构资源进行池化和统一调度管理。通过SDN技术实现网络资源的虚拟化和集中控制;通过主机虚拟化和云计算技术实现VM的创建、迁移、克隆和快照等管理功能;通过统一的资源管理系统实现网络、计算、存储、数据库、安全等各类资源的申请、管理以及自动化部署,并且可以提供完善的目服务门户。'
    },
    operation: {
      p_1: '在互联网+、工业4.0的推动下,伴随着云、大数据、移动互联网和物联网的飞速发展. IT已经进入全新时代。IT所提供的服务也从传统的满足企业T需求、运行和维护门基础设施发展到协助企业应对各种问题, IT也不再局限于技术层面, CIo们需要考虑如何有效地将IT基础设施与业务战略整合,以提高企业核心竞争力,帮助企业改进服务,降低成本,规避风险',
      span_1: '据调研数据显示,clo们最关注得七大挑战',
      h4_2: '管理优化',
      p_2: 'clo们最为关注的七大挑战中的一半以上都与IT管理的优化与提升相关,clo们已经认识到,提升IT管理的推动业务与信息化融合的关键手段,也是满足愈加严格的安全及合规要求,应对企业快速发展的管理需要和迅速融入新IT时代的必经之路。',
      span_2_top1: '优化',
      span_2_top2: 'Jos管理服务',
      span_2_top3: '咨询',
      span_2_top4: '设计',
      span_2_top5: '建设',
      span_2_top6: '运维',
      h4_3_top1: '丰富的服务经验',
      p_3_top1: '凭借跨越技术和行业领域的能力,确保客户规造管理风险,对本地市场及行业需求具有深层次了解。',
      h4_3_top2: '降低服务成本',
      p_3_top2: '根据业务需求或项目需要,灵活制定服务方案,以提升企业T管理服务质量的同时降低服务成本。',
      h4_3_top3: '高质量和标准',
      p_3_top3: '具备国际专业认证(包括SO, TIL PMP等),拥有专业管理服务流程,致力提升服务质量和用户体验。',
      h4_3_top4: '业内专业团队',
      p_3_top4: '拥有超过250名专业技术人员,并获得业内 CCIE. CCNP, MCSE. PMP. TIL等专业认证,实现统一交付能力。',
      h4_3_top5: '广泛服务区域覆盖',
      p_3_top5: '服务范围覆盖70多个城市,在全国3个中心城市设立分支机构,4个一级城市建立备件仓,18个二级城市拥有备件库。',
      h4_3_top6: '灵活的服务方案',
      p_3_top6: '凭借专业的技术人才及丰富经验的实施团队,能根据客户的需求定制多样化服务方案,实现企业资源优化配置。',
    },
    IDCServices: {
      h2_1: '地域可用区 架构高可用',
      p_1: '地域（Region）指的是根据地理位置不同将同一地区的云服务组成合集,成为一个地域。可用区（Availability Zones）是指在同一个地域之内的一组数据中心群,一个地域内一般会有多个可用区。',
      h4_2_1: '基建的标准',
      p_2_1: 'T3+ 级别或者以上,机柜数 >500 个,2×400G 异路由光缆互联 POP',
      h5_3_top1: '电力保障体系标准',
      p_3_top2: 'N+2 冗余备份',
      h5_4_top1: '安全保障体系标准',
      p_4_top2: '7x24 小时安全保障系统',
      h5_5_top1: '消防保障体系标准',
      p_5_top2: '全年全天候烟感温感探测',
      h5_6_top1: '环境控制体系标准',
      p_6_top2: '恒温 | 恒湿',
      h4_7: '可用区优势',
      h5_8_top1: '隔离',
      p_8_top2: '完全物理隔离',
      h5_9_top1: '超低',
      p_9_top2: '超低通讯延时',
      h5_10_top1: '灵活',
      p_10_top2: '网络接入灵活',
      h5_11_top1: '稳定',
      p_11_top2: '容灾架构稳定',
      h2_12: '为什么选择汉博利特？',
      p_12: '公司成立十多年来,专注于电信增值业务服务和信息化建设,为客户提供一站式解决方案,信息安全科研团队的整体技术实力达到国内领先水平。',
      h4_13_top1: '13',
      p_13_top2: '成立于2014年 , 超过13年IDC运营经验',
      h4_14_top1: '30',
      p_14_top2: '机房数量超过30个,均为中心或骨干节点',
      h4_15_top1: '1000',
      p_15_top2: '拥有海量宽带资源,全网处理能超过1000G',
      h4_16_top1: '20,000',
      p_16_top2: '运维服务器数量超过20000台技术力量雄厚,经验丰富',
      h4_17_top1: '300,000',
      p_17_top2: '服务30万中小企业及大型企业客户',
      h2_18: '他们都在用',
      h4_19_top1: 'QQ彩票',
      p_19_top2: '国内最大的彩票平台',
      h4_20_top1: '微信红包',
      p_20_top2: '春晚红包案例',
      h4_21_top1: '手机QQ',
      p_21_top2: '全球最大的社交平台',
      h4_22_top1: '地下城与勇士',
      p_22_top2: '大型RPG游戏',
    },
  },
  selectConfig: {
    rests: '其他',
    huawei: '华为',
    ruijie: '锐捷',
    hikvision: '海康威视',
    switch: '交换机',
    router: '路由器',
    firewall: '防火墙',
    ac: 'AC控制器',
    server: '服务器',
    storage: '存储',
    storageBackupSoftware: '存储备份软件'
  },
  recruitment: {
    department: '所属部门',
    workRegion: '工作地区',
    wage: '薪资范围',
    lookDetail: '点击查看详情',
    weNeed: '我们需要',
    contactWay: '有意者请联系 (非诚勿扰)',
    phone: '电话: 010-60531520',
    email: "邮箱: hr{'@'}humblit.com",
  },
  warranty: {
    banner_medium: '/resource/image/banner.png',
    banner_small: '/resource/image/ban1.png',
    consult: '我要咨询',
    h2_1: '购买DELL EMC延长保修,你将得到以下服务',
    h4_1_top1: '4小时上门',
    p_1_top2: '全年365天,当天4小时派遣现场服务技术人员上门服务以及更换部件。',
    h4_2_top1: '24/7*365支持',
    p_2_top2: '7X24专家中心电话和在线支持, 接线时间更短,一般DELL工程师会在45秒接起电话。',
    h4_3_top1: '(24X7) 远程技术支持',
    p_3_top2: '全天候 (24X7) 远程 监控和维修、DELL EMC产品将自动和独立地联系 Dell EMC 来提供输入,以协助 Dell',
    h4_4_top1: '快速通道派遣',
    p_4_top2: '戴尔专业认证-跳过故障诊断,快速获得部件/工程师。',
    h4_5_top1: '在线支持工具',
    p_5_top2: '通过 Dell EMC 在线支 持网站,全天候 (24x7) 访问Dell EMC的网络知识和自助客户支持工具。',
    h4_6_top1: '软件协作支持',
    p_6_top2: '与第三方供应商的协作解决技术问题,包括EQL/EMC核心软件更新。',
    h4_7_top1: '更换固态硬盘',
    p_7top2: '如果任何固态硬盘在达到或低于容量上限之前耐久性级别较低,则客户有资格获得更 换固态硬盘。',
    h4_8_top1: '专职服务经理',
    p_8_top2: '技术服务经理可提供大量系统、环境和帐户管理功能和能力的远程资源,旨在减少停机时间并改善来自 Dell EMC 的整体支持体验。',
    h2_9: '服务流程',
    p_9: '专业的服务流程 优致的服务体验',
    img_9_medium: '/resource/image/fuw.png',
    img_9_small: '/resource/image/shou.png',
    img_10_medium: '/resource/image/f14.png',
    img_10_small: '/resource/image/f15.png',
    h2_11: '支持以下产品购买延长保修服务',
    h4_11_top1: '服务器',
    p_11_top2: 'VxRail PowerFlex PowerEdge',
    h4_12_top1: '存储设备',
    p_12_top2: 'PowerMax PowerFlex XtremIO PowerStore Unity SC Series PowerVault PowerScale ECS VPLEX VNX VNXE VMAX isilon CLARiiON Celerra PowerProtect DD 虚拟带库',
    h4_13_top1: '数据保护',
    p_13_top2: 'PowerProtect DD PowerProtect X IDPA DP',
    h4_14_top1: '网络产品',
    p_14_top2: 'PowerSwitch Connectrix SAN',
    h4_15_top1: '备份软件',
    p_15_top2: 'SmartFabric PowerProtect Data Manager Data Protection Suite PowerPath SRM AppsYnc CloudLink',
    h4_16_top1: 'PC与显示设备',
    p_16_top2: 'OptiPlex Latitude Precision UltraSharp',
    h2_17_top1: '我们已为',
    h2_17_top2: '台设备提供了续保服务',
    p_17_top3: '专业的服务流程 优致的服务体验',
    input_17_top4: '手机号码',
    input_17_top5: '请输入您的手机号码',
    select_17_top6: '产品类型',
    select_17_top7: '请选择产品类型',
    button_17: '我要咨询',
    h2_18: '材料下载',
    span_18_top1: '售后标准支持文档',
    span_18_top2: '面向企业支持服务文档',
    span_18_top3: '服务确认函',
  },
  contact: '联系人姓名',
  inputContact: '请输入联系人姓名',
  phone: '手机',
  inputPhone: '请输入手机',
  telephone: '电话',
  provincial: '省市区',
  selectProvincial: '请选择省市区',
  province: '省',
  selectProvince: '请选择省',
  city: '市',
  selectCity: '请选择市',
  region: '县区',
  selectRegion: '请选择县区',
  address: '详细地址',
  inputAddress: '请输入详细地址',

  email: '邮箱',
  inputEmail: '请输入邮箱',
  messagePrompt: '请告诉我们,我们能为你做些什么。例如,迁移问题、中国网关、安全问题等。',
  inputMessagePrompt: '请输入留言内容',
  formFillWarn: '请完整填写表单',
  submitSuccess: '提交成功',

  latestNews: '最新消息',
  newsroom: '报刊阅览',
  learnMore: '了解更多',
  seeMoreRecognition: '查看更多',
  watch: '查看',
  contactUs: '联系我们',
  contactUsDesc: '大家一起说了解更多关于HCLTech和我们如何与我们的客户、我们的员工和我们的社区加速进展。',
  headquarter: '国际总部',
  headquarterLocation: '香港华联科技有限公司香港经济特区科技中心印度诺伊达- 201304区126 |区3A号地块',
  subscribe: '订阅',
  menu: '菜单',
  submit: '提交',
  search: '搜索',
  searchFor: '搜索',
  searchFound: '搜索到',
  items: '条',
  checkReminder: '请完成表单必填项',
  noSearchResults: JSON.stringify([
    '没有搜索到结果。',
    '检查您的拼写是否正确。',
  ]),
  loadMore: '加载更多',
  succeed: '成功',
  submitted: '已提交',
  submitFailure: '提交失败,请稍后重试',
  confidentialText: '我们将对您向我们提交的任何信息予以保密。',
  filiale: '分公司',
}