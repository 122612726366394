<template>
   <transition name="fade" @before-leave="onClose" @after-leave="onDestroy">
    <div :style="{ top: topOffset + 'px' }" :class="messageClass" class="message" v-if="visiable">
      {{ message }}
    </div>
  </transition>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
export default {
  name: 'MessageBox',
  props: {
    topOffset: {
      type: Number,
      required: true,
    },
    type: {
      default: 'info',
      validator: v => ['success', 'warning', 'info', 'error'].includes(v)
    },
    message: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      default: 3000,
    },
    id: {
      type: Number,
      required: true,
    },
    onDestroy: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  setup (props) {
    const messageClassList = {
      success: 'message-success',
      warning: 'message-warning',
      info: 'message-info',
      error: 'message-error'
    }
    const data = reactive({
      visiable: false,
      messageClass: messageClassList[props.type]
    })
    onMounted(() => {
      data.visiable = true;
      setTimeout(() => {
        close();
      }, props.duration);
    })
    const close = () => {
      data.visiable = false;
    }
    return {
      ...props,
      ...toRefs(data)
    }
  }
} 
</script>

<style lang="scss" scoped>
.message {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 10px);
  padding: 15px 20px;
  max-width: 300px;
  z-index: 10001;
  transition: top 0.4s;
  font-size: 16px;
  border-radius: 6px;
  overflow: hidden;
}

.message-success {
  border: 1px solid #e1f3d8;
  background: #f0f9eb;
  color: #67c23a;
}
.message-warning {
  border: 1px solid #faecd8;
  background: #fdf6ec;
  color: #e6a23c;
}
.message-info {
  border: 1px solid #e9e9eb;
  background: #f4f4f5;
  color: #909399;
}
.message-error {
  border: 1px solid #fde2e2;
  background: #fef0f0;
  color: #f56c6c;
}

.box-rigth {
  width: 100%;
  height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: transform 0.4s, opacity 0.4s;
}

.fade-enter-from {
  transform: translate(-50%, 0);
  opacity: 0;
}


.fade-leave-to {
  transform: translate(-50%, -10px) !important;
  opacity: 0;
}
</style>