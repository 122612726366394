import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import VueI18n from './language'
import BackgroundView from '@/components/backgroundView/Background.vue'
import 'lib-flexible'
import message from "@/components/message";

const app = createApp(App)
const pinia = createPinia()

app.config.globalProperties.$message = message

app.use(router)
app.use(pinia)
app.use(VueI18n)
app.component('background-view', BackgroundView)
app.mount('#app')

