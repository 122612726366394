<template>
  <router-view :key="$route.path"/>
</template>

<script setup>
import { onMounted } from 'vue'
import { useMainStore } from '@/store/useMainStore'
const store = useMainStore();
const setSelfAdaptiveType = () => {
  const selfAdaptiveType = window.getComputedStyle(document.body).getPropertyValue('--self-adaptive-type').trim()
  store.setSelfAdaptiveType(selfAdaptiveType)
}
onMounted(() => {
  setSelfAdaptiveType()
  window.addEventListener('resize', () => {
    setSelfAdaptiveType()
  })
})
</script>

<style lang="scss">
@import '@/styles/global.scss';
@import '@/assets/font/iconfont.css';
@media screen and (min-width: 990px) {
  :root {
    --self-adaptive-type: medium;
  }
}
@media screen and (max-width: 989px) {
  :root {
    --self-adaptive-type: small;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --self-adaptive-type: mini;
  }
}
</style>
